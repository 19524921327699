import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Nichts gefunden! 404" />
      <center>
        <br />
        <br />
        <h1>Wo ist das Baby? :)</h1>
        <p>
          Diese Seite ist <b>nicht</b> oder nicht mehr verfügbar...
          <br />
          Auf{" "}
          <a
            href="https://schlafgut-schlafberatung.de/"
            title="schlafgut-schlafberatung.de"
          >
            https://schlafgut-schlafberatung.de/
          </a>{" "}
          findest du mich wieder!
        </p>
        <br />
        <br />
      </center>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
